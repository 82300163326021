import React from 'react'

import { useIntl } from "gatsby-plugin-intl"
import { graphql } from "gatsby";

import { ImageDataLike } from 'gatsby-plugin-image';

import { ClientInfo } from '../../sections/ClientInfo';
import { ClientHero } from '../../sections/ClientHero';
import { RelatedWork } from '../../sections/RelatedWork';
import { ContactUs } from '../../sections/ContactUs';
import { Carrousel } from '../../sections/Carrousel';

import { Layout } from '../../components/Layout'
import SEO from '../../components/SEO/SEO'

import { WorkContainer, InfoSection } from './styles';

  export const query = graphql`
    query ($id: ID!) {
      cms {
        work(id: $id) {
          data {
            id
            attributes {
              Project_Title
              Subtitle
              Project_Description
              Categories
              Industry
              Project_Website
              Project_Hero_Media {
                data {
                  attributes {
                    url
                    urlSharp {
                      childImageSharp {
                        gatsbyImageData
                      }
                      publicURL
                    }
                  }
                }
              }
              Project_Hero_Media_Mobile {
                data {
                  attributes {
                    url
                    urlSharp {
                      childImageSharp {
                        gatsbyImageData
                      }
                      publicURL
                    }
                  }
                }
              }
              Project_Carrousel_Slider {
                Carrousel_Slider_Media{
                  data {
                    attributes {
                      url
                      urlSharp {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                  }
                }
              }
              Client_Logo {
                data {
                  attributes {
                    url
                    urlSharp {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
              Thumbnail_Image {
                data {
                  attributes {
                    url
                    urlSharp {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                      }
                    }
                  }
                }
              }
            }
          }
        }
        works (pagination: {limit: 200},filters: {id: {ne: $id}}){
          data {
            id
            attributes {
              Project_Title
              Subtitle
              Categories
              Industry
              Main_Project_Name
              Thumbnail_Image {
                data {
                  attributes {
                    url
                    urlSharp {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                      }
                    }
                  }
                }
              }
            }
          }
        }  
      }
    }
  `

const Work: React.FC<WorkProps> = ({ data }) => {
  const {id} = data.cms.work.data
  const { Project_Title, Subtitle, Project_Description, Project_Website, Categories, Industry, Thumbnail_Image } = data.cms.work.data.attributes
  const allWorks = data.cms.works.data
  const clientLogo = data.cms.work.data.attributes.Client_Logo
  const clientIndustry = data.cms.work.data.attributes.Industry
  const intl = useIntl();
  return (
    <Layout initialTextColor="white" landing="work">

      <SEO
        pageTitle={intl.formatMessage({ id: Project_Title })}
        description={intl.formatMessage({ id: Subtitle })}
        twitterSite="@wearepsh"
        twitterTitle={intl.formatMessage({ id: Project_Title })}
        ogUrl={`https://wearepsh.com/work/${Project_Title.replace(/\s/g, '_')}`}
        ogDescription={intl.formatMessage({ id: Subtitle })}
        ogImage={Thumbnail_Image.data.attributes.url}
      />

      <WorkContainer>
        <ClientHero  heroMediaMobile={data.cms.work.data.attributes.Project_Hero_Media_Mobile.data.attributes} heroMedia={data.cms.work.data.attributes.Project_Hero_Media.data.attributes} Project_Title={Project_Title} subtitle={Subtitle}/>
        <InfoSection>
          <ClientInfo website={Project_Website} info={Project_Description} Industry={clientIndustry} Categories={Categories} clientLogo={clientLogo}/>
        </InfoSection>
        <Carrousel slidersArray={data.cms.work.data.attributes.Project_Carrousel_Slider} buttonColor='#223238' />
        <RelatedWork Project_Title={Project_Title}  industry={Industry} ownCategories={Categories}  allWorks={allWorks} id={id}/>
        <ContactUs backgroundColor = '#F8F8F8'/>
      </WorkContainer>
    </Layout>
  )
}

interface WorkProps {
  data: {
    cms:{
      work: {
        data: {
          id: number;
          attributes: {
            Project_Title: string;
            subtitle: string;
            Project_Description: string;
            Categories: object;
            Industry: object;
            Project_Website: string;
            Project_Hero_Media: Media;
            Project_Hero_Media_Mobile: Media;
            Project_Carrousel_Slider: {
              Carrousel_Slider_Media: Media 
            }[];
            Client_Logo: Media; 
          }
        }
      }
      works: {
        data: {
          id: string;
          attributes: {
            title: string;
            Subtitle: string;
            Categories: object;
            Industry: object;
            Thumbnail_Image: Media;
          }
        }
      }  
    }
  }
}

export interface Media {
  data: {
    attributes: {
      url: string;
      urlSharp: ImageDataLike;
    }
  }
}

export default Work;